/* Resetting defaults */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding and border are included in element's total width and height */
}

body, html {
  height: 100%;
  font-family: 'Inter Tight', sans-serif;
  background-color: #ffffff;
  margin: 0; /* Remove default body margin */
}

/* Centering content with Flexbox */
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  text-align: center; /* Center text inside the container */
}

/* Content wrapper for consistent alignment */
.content-wrapper {
  display: flex;
  flex-direction: column; /* Stack elements vertically on mobile */
  align-items: center; /* Center content horizontally */
  text-align: justify; /* Stretches the lines so that each line has equal width */
}

.content-wrapper p,
.content-wrapper img {
  width: 100%; /* Ensure elements take full width on mobile */
  max-width: 100%; /* Ensure images don't overflow on smaller screens */
  margin-bottom: 20px; /* Add spacing between elements */
}

.content-wrapper img {
  border-radius: 10px; /* Optional: Add rounded corners to images */
}

.highlight-text {
  background: linear-gradient(90deg, #1af9a0, #f15d27); /* Define your gradient colors here */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: bolder;
}

/* Section styles */
.section1, .section2, .section3 {
  width: 100%;
  padding: 20px;
}

.section1 {
  background-color: #fdfdfd;
}

.section2 {
  background-color: #ffffff;
  color: #333;
  margin-left: -20px; /* Offset the left margin to align with section 1 */
  margin-right: -20px; /* Offset the right margin to align with section 3 */
}

.section2 img {
  max-width: 100%;
  height: auto;
}

.section3 {
  background-color: white;
  padding-top: 20px; /* Add padding to the top of the section */
  padding-bottom: 40px; /* Add padding to the bottom of section 3 */
}

.section3 a {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3c99f6;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 20px; /* Ensure spacing between the link and the footer */
}

.section1 h1 {
  color: #333;
  font-size: 50px;
}

.section1 h2 {
  color: #666;
  font-size: 35px;
}

/* General styling for section content */
.section2 p {
  color: #000000;
  font-size: 18px;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
}

/* Add some margin to the image to avoid overlapping */
.route-map {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 20px auto; /* Center the image by setting left and right margins to auto */
}

/* Start and Finish Points */
.start-point,
.finish-point {
  display: inline-block;
  margin-bottom: 10px; /* Add margin to avoid overlap */
}

.start-point {
  background-color: #1AE6C1; /* Green color for starting point */
  padding: 5px 15px;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
}

.finish-point {
  background-color: #d65764; /* Red color for finishing point */
  padding: 5px 15px;
  border-radius: 5px;
  color: white;
}

/* Responsive design */
@media (max-width: 768px) {
  .centered-container {
    padding: 10px;
  }

  .section1 h1 {
    font-size: 28px;
  }

  .section1 h2 {
    font-size: 20px;
  }

  .section2 p, .section2 img, .section3 a {
    font-size: 16px;
  }

  .start-point,
  .finish-point {
    display: block;
    width: 100%;
    margin-bottom: 10px; /* Ensure spacing between points */
  }
}

/* Media query for desktop */
@media (min-width: 768px) {
  .content-wrapper {
    flex-direction: row; /* Arrange elements side by side */
    justify-content: center; /* Center elements horizontally */
    text-align: justify;
  }

  .content-wrapper p,
  .content-wrapper img {
    width: auto; /* Reset width to auto for desktop */
    max-width: 50%; /* Set maximum width for paragraphs and images */
    margin-right: 20px; /* Add spacing between elements */
    margin-bottom: 0; /* Remove bottom margin for paragraphs */
  }

  .content-wrapper img:last-child {
    margin-right: 0; /* Remove margin for the last image */
  }
}

#newsletter-signup {
  padding-bottom: 100px; /* Adjust this value as needed */
}


